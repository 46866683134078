#compteLyceeRabelais{
    .tabs-container{
        & > .nav li {
            width: auto;
            & > a{
                padding: 14px 25px;
            }
        }
    }
}
