.agenceKubiweb {
    height: 40px;
    margin-top: 18px;
    a {
        color: #888888 !important;
        &:after {
            content: none;
        }
    }
    .dropdown-menu {
        right: auto!important;
    }

}
.badge {
    display: inline-block;
    min-width: 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: rgb(119, 119, 119);
    padding: 3px 7px;
    border-radius: 10px;
    margin-left: -8px;
    margin-bottom: -4px;
    background-color: rgb(217, 83, 79);
}
.wordPressHead {
    height: 40px;
    margin-top: 18px;
}

.menu-compte-haut {
    margin-top: 5px;
    padding: 15px;
    .nav > li > a{
        color: #676a6c;
        font-weight: 600;
    }
    .active a{
        color: #ffffff!important;
    }
    .title {
        line-height: 40px;
        font-size: 23px;
        a{
            color: #000000;
        }
    }
}

.navbar-static-side{
    width: 250px;
}
.ibox-tools {
    a {
        color: #ffffff!important;
    }
    &.chart {
        a {
            color: #c4c4c4 !important;
        }
    }
}
.addTache a{
    font-size: 35px;
    color: red;
}

#tabAffaire .dropdown-toggle::after, .carnet .dropdown-toggle::after{
    content: none;
}
.caTable{
    color: red;
    font-weight: bold;
}

#tabAffaire .date {
    margin-top: 5px;
    margin-left: 5px;
}

.unCol{
    line-height: 70px;
}

.removeTag {
    margin-left: 15px;
    background-color: #ec4758;
    border-color: #ec4758;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}
.require{
    color: red;
}

.ajout_rentab a {
    font-size: 14px;
    background: #23405a !important;
    color: #fff !important;
    text-align: center !important;
    padding: 4px 8px !important;
    height: auto !important;
    border-radius: 4px;
    /* margin-bottom: 16px; */
}
#ajouterfactures .dropdown-toggle::after, .carnet .dropdown-toggle::after{
    content: none;
}

.ibox-content{
    border-color: transparent;
}

#ajouter {
    .dropdown-toggle::after {
        content: none;
    }
    button {
        border: 1px solid #d43f3a;
        background-color: #ffffff;
        color: #d43f3a;
        height: 35px;
        margin-top: 10px;
    }
}

.caTable{
    color: red;
    font-weight: bold;
}

.payment-card .row {
    font-size: 16px;
    margin-bottom: 15px;
}
.payment-card .row .text-right{
    color: #fc7914;
}

.ajout_rentab a {
    font-size: 14px;
    background: #23405a !important;
    color: #fff !important;
    text-align: center !important;
    padding: 4px 8px !important;
    height: auto !important;
    border-radius: 4px;
    /* margin-bottom: 16px; */
}
#ajouterfactures .dropdown-toggle::after, .carnet .dropdown-toggle::after{
    content: none;
}

.navbar-home {
    padding: 0 12px;
    margin: 13px 5px 17px 15px;
    font-size: 20px;
    float: left;
    i {
        font-size: 21px;
        color: #888888;
    }
}

.navbar-top-links {
    .fa-laptop {
        font-size: 21px;
    }
    li {
        a {
            padding: 11px 10px;
        }
    }
}

body {
    &.mini-navbar {
        .nav-header {
            background-color: transparent;
        }
        .logo-element {
            width: 50px;
            height: 50px;
            line-height: 50px;
            padding: 0;
            border-radius: 50%;
            background-color: white;
            margin: 5px auto;
            .dropdown-toggle {
                color: red;
                &:after {
                    display: none;
                }
            }
        }
    }
}

/*Custom Style*/
/***********
Generic
***********/
a {
    color: #2d5374;
}

a:hover {
    text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
    color: #2d5374;
    font-weight: bold;
}

.no-caret {
    &:after {
        content: none;
    }
}

.menu-elements {
    .nav {
        &> li {
            &> a {
                font-size: 15px;
            }
        }
    }
}

.navbar-top-links {
    div.dropdown-menu {
        min-width: 500px;
        ul {
            padding: 0;
            margin: 0;
            &> li {
                float: left;
                width: 33.33%;
                &> a {
                    color: inherit;
                    line-height: 25px;
                    font-weight: normal;
                    display: block;
                    padding: 20px 10px;
                    font-size: 12px;
                    .fas {
                        display: block;
                        margin: 0 auto 5px;
                        font-size: 25px;
                    }
                }
            }
        }
    }
}

/************
Datatables
*************/
table.table {
    border-collapse: collapse !important;
}
table.table > thead > tr  th, table.table > thead > tr  .sorting {
    background-color: #293846;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    white-space: nowrap;
}

table.table > thead > tr.bg-grey  th, table.table > thead > tr.bg-grey  .sorting {
    background-color: #f1f1f1;
    color: #23405a;
}

table.table thead .sorting:after, table.table thead .sorting_asc:after, table.table thead .sorting_desc:after, table.table thead .sorting_asc_disabled:after, table.table thead .sorting_desc_disabled:after {
    bottom: 11px;
}

table.table > tbody > tr > td {
    vertical-align: middle;
}

table.table > tbody > tr > td a {
    font-weight: bold !important;
}

/**************
Pagination
**************/
.pagination{
    &> li {
        &> a, &> span {
            padding: 6px 12px;
        }
    }
}

/**************
Custom styles
**************/
.subtitle {
    font-size: 14px;
    font-weight: 100;
}

.modal-header {
    .close {
        margin-top: -40px;
        font-size: 40px;
    }
}

.custom-checkbox {
    display: none;
    &+ label {
        position: relative;
        &:before {
            content: "";
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 3px;
            border: 1px solid #e7eaec;
            vertical-align: middle;
        }
        &:hover {
            &:before {
                border: 1px solid #18a689;
            }
        }
    }
    &:checked {
        &+ label {
            &:before {
                background-color: #18a689;
                border-color: #18a689;
            }
            &:after {
                content: "\f00c";
                color: #FFF;
                display: inline-block;
                font-size: 11px;
                font-family: 'Font Awesome 5 Free';
                font-weight: 600;
                position: absolute;
                top: 3px;
                left: 5px;
            }
        }
    }
}

.clockpicker-popover, .colorpicker {
    z-index: 10000;
}

/**************
Widget
**************/

.widget {
    display: block;
    overflow: hidden;
    position: relative;
    &:hover {
        color: #FFF;
        text-decoration: none;
    }
    &.h200 {
        min-height: 200px;
    }
    &.h300 {
        min-height: 305px;
    }

    h2 {
        font-size: 28px;
        color: #FFF;
        font-weight: 800;
        line-height: normal;
    }

    span {
        font-size: 16px;
        line-height: normal;
        display: block;
        margin-bottom: 5px;
        font-weight: 300;
        &.widget-title {
            font-size: 28px;
        }
    }

    .p-m {
        padding: 15px;
        span {
            margin-bottom: 0;
        }
    }

    .set-background {
        position: absolute;
        bottom: 5px;
        right: -44px;
        font-size: 128px;
        transform: rotate(2deg);
        opacity: 0.3;
        &.business {
            right: -10px;
        }
        &.client {
            font-size: 250px;
            right: 3px;
            bottom: -2px;
            transform: rotate(5deg);
        }
        &.supplier {
            font-size: 250px;
            right: 3px;
            bottom: -2px;
            transform: rotate(5deg);
        }
    }
}

.ibox-title {
    background-color: #23405a;
    h5 {
        color: white;
    }
    &.transparent {
        background-color: #FFF;
        h5 {
            color: #2d5374;
        }
    }
}

.chat-element {
    .avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #f1f1f1;
        color: red;
        display: block;
        font-weight: bold;
    }
}

.carnet a {
    display: inline-block;
}
