#financiereModule > .wrapper-content:first-child{
    position: relative;
    z-index: 9;
}
.affaire_bottom {
    .fa-exclamation-triangle{
        color: inherit;
    }
}
.wrap_gauge{
    display: flex;
    align-items: center;
    justify-content:center;
    margin-top: -5em;
    &  > div {
        margin: 0 2em;
    }
    .c3-chart-arc .c3-gauge-value{
        display: none;
    }
    .c3 ~ span{
        display: block;
    }
    .g_devis span{
        color: #ED5565;
    }
    .g_projet span{
        color: #1ab394;
    }
    span{
        font-size: 1.5em;
        text-align: center;
        margin-top: -1.5em;
        display: none;
    }
}
.wrap_select {
    margin: 1em 0 0;
}
.row-header {
    .carnet{
        padding-top: 4em;
        .date,
        a{
            color: #000
        }
        .fa{
            font-weight: 400;
            font-size: 2em;
        }
    }
    .colActions{
        text-align: left;
        .dropdown_actions{
            display: inline-block;
            position: relative;
        }
        .inner-actions{
            text-align: center;
            a{
                color: #2d5374;
            }
        }
        .dropdown_menu{
            display: none;
            border: 1px solid #000;
            padding: 0;
            list-style: none;
            margin: .2em 0 0;
            text-align: left;
            font-size: 1.2em;
            line-height: 2.5em;
            color: #454748;
            width: 260px;
            position: absolute;
            background: #fff;
            z-index: 999;
            li{
                cursor: pointer;
                padding: .2em 1em;
                transition: all 400ms ease-in;
                -webkit-transition: all 400ms ease-in;
                -moz-transition: all 400ms ease-in;
                &:hover{
                    color: #2e5475;
                }
            }
            .ico{
                font-family: 'Font Awesome 5 Free', 'Open Sans';
                font-weight: 700;
                font-size: 1.1em;
                display: inline-block;
                vertical-align: middle;
                margin-right: .5em;;
            }
        }
        .dropdown_value{
            padding: 12px;
            border: 1px solid #000;
            color: #000;
            font-size: 1.2em;
            width: 260px;
            display: inline-block;
            text-align: left;
            cursor: pointer;
            .ico{
                font-family: 'Font Awesome 5 Free', 'Open Sans';
                font-weight: 700;
                font-size: 1.1em;
                display: inline-block;
                vertical-align: middle;
                margin-right: .5em;;
            }
            .fas{
                float: right;
                line-height: 22px;
                display: inline-block;
                margin-top: .1rem;
            }
        }
    }
    .affaire_info{
        &.affaire_commande{
            .statut{
                background: #57b495;
            }
        }
        &.affaire_devis{
            .statut{
                background: #f9a65e;
            }
        }
        display: inline-block;
        vertical-align: middle;
        color: #3b5e7c;
        background: #f5f6f8;
        border-radius: .6em;
        -webkit-border-radius: .6em;
        -moz-border-radius: .6em;
        .statut{
            display: inline-block;
            vertical-align: middle;
            background: #57b495;
            color: #ffffff;
            padding: .3em 1.5em;
            font-size: 1.3em;
            min-width: 196px;
            text-align: center;
            border-radius: .6em;
            -webkit-border-radius: .6em;
            -moz-border-radius: .6em;
        }
        .candidature{
            display: inline-block;
            vertical-align: middle;
            padding: .3em 1.5em;
            font-size: 1.3em;
            min-width: 196px;
            text-align: center;
            border-radius: .6em;
            -webkit-border-radius: .6em;
            -moz-border-radius: .6em;
        }
        .affaire_name{
            font-size: 1.3em;
            padding: 0 2em;
            display: inline-block;
            vertical-align: middle;
            font-weight: 700;
            min-width: 270px;
        }
    }
    .affaire_price{
        display: inline-block;
        vertical-align: middle;
        padding: 0 1em;
        font-size: 1.2em;
        color: #151515;
        .price{
            font-size: 1.4em;
            color: #ed5666;
            font-weight: 700;
        }
    }
    .affaire_menu{
        margin: 3em 0 0;
        padding: 0;
        list-style: none;
        li {
            padding: 0 2.5em;
            span{
                display: block;
                font-size: 1.2em;
            }
            a{
                display: block;
                text-align: center;
                &.active_menu,
                &:hover{
                    color: #2d5374;
                    text-decoration: none;
                    span{
                        color: #ed5565;
                    }
                }
            }
            .fa{
                font-size: 4em;
                display: inline-block;
                margin: 0 0 .1em;
                &.fa-money-bill-alt{
                    font-weight: 400;
                }
            }
        }
    }
    .wrap_toggle{
        padding: 1em 0 0;
        float: right;
        .dropdown-menu,
        .dropdown-item,
        .dropdown-item:hover,
        a:hover,
        a{
            color: #8d9192;
        }
        .dropdown-item{
            width: 100%;
            padding: 3px 20px;
            line-height: 25px;
        }
        .dropdown-menu{
            left: auto !important;
            right: 1em;
            & > li > a{
                margin: 0;
            }
        }
        .fa-trash {
            font-size: 14px;
        }
    }
}
.affaire_content{
    .form-group {
        margin-bottom: 2.4em;
        .fa-exclamation-triangle{
            color: #f9e812;
        }
    }
    .nav > li > a {
        padding: 14px;
    }
    .progress-title{
        margin: 0 0 .5em;
        font-weight: 400;
        color: #080808;
        font-size: 22px;
    }
    .inner-form{
        margin-left: 30px;
    }
    .panel-body{
        padding: 20px;
        border:0;
    }
    .fa-address-card{
        font-weight: 400;
    }
    .label-title{
        font-size: 18px;
        margin-top: 0px;
        font-weight: bold;
        margin-left: 0;
        margin-bottom: 1em;
        i{
            margin-right: 5px;
        }
        .btn{
            font-size: .7em;
            padding: .375rem 2em;
            margin-left: 1rem;
        }
    }
    .titreTexte {
        cursor: pointer;
    }
    .titreInput {
        display: none;
        width: 80%;
        input{
            width: 80%;
            font-size: 16px;
            border-color: #2d5374;
            display: inline-block;
        }
        img{
            margin-left: .5em;
        }
    }
    .titre-content {
        padding: 10px 0;
        .titreTexte {
            font-size: 16px;
            margin-left: 30px;
            padding: 7px 10px;
            background-color: rgba(9,30,66,.04);
        }
        .titreDate{
            font-size: 1.1em;
            color: #2d5374;
            font-weight: bold;
            & ~ .titreTexte{
                margin-left: 5px;
            }
        }
    }
    .description-content-textarea,
    .description-content{
        margin-left: 28px;
        margin-top: 15px;
    }
    .description-content div{
        cursor: pointer;
        background-color: rgba(9,30,66,.04);
        box-shadow: none;
        border: none;
        border-radius: 3px;
        display: block;
        min-height: 90px;
        padding: 8px 12px;
        text-decoration: none;
        color: #172b4d;
    }
    .description-content-textarea {
        display: none;
        textarea{
            min-height: 90px;
        }
    }
    .table_wrap_contact{
        background: #f5f6f8;
        padding: 0em 1em;
        font-size: 1.1em;
        color: #080808;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        .plus-info{
            cursor: pointer;
            font-size: .6em;
            text-decoration: underline;
        }
        tr,
        td{
            cursor: default;
        }
        tr{
            border-bottom: 1px solid #e0e0e0;
            &:last-child{
                border-bottom: 0;
            }
        }
        td{
            padding: .7em 0;
            &:last-child{
                text-align: right;
            }
        }
    }
    .selectTache{
        width: 86%;
        display: inline-block;
        min-height: 43px;
        margin-right: 1em;
    }
    .valideTache{
        color: #28a745;
        //display: none;
        font-size: 20px;
        height:43px;
        visibility: hidden;
        &[style *="display: inline-block"]{
            visibility: visible;
        }
    }
    .nonvalideTache{
        color: red;
        font-size: 20px;
        display: none;
        height: 43px;
    }
    .btn_impaye{
        margin-bottom: 2em;
        .btn{
            background: #fff;
            border: 1px solid #f5f6f8;
            padding: .3rem 3rem;
            color: #4d6e89;
            font-size: 1.3em;
            margin-right: 1em;
        }
        .fa{
            color: #f9e812;
            font-size: 2em;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .listReporting{
        .addReporting{
            text-align: right;
            margin-bottom: 2em;
        }
    }
    .listDocument{
        background: #fff;
        margin-bottom: 2em;
        .btn{
            font-size: 1em;
            padding: .375rem 2.1em;
            margin-left: 1rem;
        }
        .rightModal{
            text-align: right;
        }
    }
    #tab-info{
        .fa-exclamation-triangle{
            color: #f9e812
        }
        .dataTables_filter input{
            max-width: 164px;
        }
        #rappelCompte_wrapper > div:first-child{
            position: absolute;
            top: 0;
            margin-top: -57px;
        }
    }
    .unTag_affaire {
        .btn:not(.btn-warning){
            background-color: #f5f6f8;
            &:hover{
                background-color: #0a6aa1;
            }
        }
    }
}
.slider_devis{
    border: 0 !important;
    background: #ed5666;
    height: 5px;
    border-radius: 5px;
    .ui-slider-range{
        background: #e9ecef;
    }
    .ui-slider-handle{
            width: 1em;
            height: 1em;
            cursor: default;
            background: #ed5767 !important;
            border: 0 !important;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
        }
}
.slider_projet{
    @extend .slider_devis;
    background: #1bb394;
    .ui-slider-range{
        background: #e9ecef !important;;
    }
    .ui-slider-handle{
        background: #1bb394 !important;
    }
}
table.table-new{
    color: #29394a;
    &.table_devis{
        .row-commande {
            td {
                background: #9aeacf;
            }
        }
    }
    & > tbody > tr > td a {
        font-weight: inherit !important;
        color: #29394a;
        text-decoration: underline;
    }
}
#rappelCompte_wrapper{
    padding: 0 0 30px;
}
#financiereModule{
    .nav > li > a {
        padding: 14px;
    }
    .payment-card{
        border: 0;
    }
}
#financiereModule{
    .wrapper-content{
        padding-bottom: 0;
    }
}
#blocFiInformation {
    .payment-card {
        display: table;
        width: 100%;
        height: 100%;
        .row .text-right {
            color: #ed5666;
        }
    }
}
#blocFiOffre{
    h3{
        margin: 0 0 20px;
    }
    table{
        ul{
            margin: 0;
            padding: 0;
            text-align: left;
            li.inline {
                vertical-align: middle;
            }
        }
    }
}
#myModalPageGarde{
    .form-group{
        margin-bottom: .5rem;
        .form-control ~ .form-control{
            margin-top: 10px;
        }
    }
}
