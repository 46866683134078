.inmodal .modal-header{
    padding-top: 15px;
    padding-left: 25px;
    text-align: left;
}
.inmodal .modal-title, .inmodal .modal-title .titreTexte{
    font-size: 18px;
    margin-top: 0px;
    font-weight: bold;
    margin-left: 0;
}
.inmodal .modal-title i{
    margin-right: 5px;
}
.inmodal .modal-title span{
    font-size: 14px;
    font-weight: normal;
    margin-left: 25px;
}
.modal-header .close{
    margin-top: -20px;
}

.details h2{
    margin-top: 30px!important;
}

.modal-title input{
    display: inline-block;
}

.titreInput {
    display: none;
    width: 80%;
}
.description-content-textarea {
    display: none;
}
.titreTexte{
    cursor: pointer;
}
.titre-content{
    padding: 10px;
}
.titre-content .titreTexte{
    font-size: 16px;
    margin-left: 15px;
    padding: 5px;
    background-color: rgba(9,30,66,.04);
    padding-left: 15px;
}

.description-content, .description-content-textarea{
    margin-left: 30px;margin-top: 15px;
}

.description-content  div{
    background-color: rgba(9,30,66,.04);
    box-shadow: none;
    border: none;
    border-radius: 3px;
    display: block;
    min-height: 40px;
    padding: 8px 12px;
    text-decoration: none;
    color: #172b4d;
}
.description-content-textarea {
    display: none;
}
.modalTache {
    .inner-left {
        .modal-title {
            margin-top: 20px !important;
        }
        .titre{
            .modal-title{
                margin-top: 0 !important;
            }
        }
    }
}
@media screen and (min-width: 1200px){
    .modalTache .modal-lg {
        max-width: 900px;
    }
}
