#tabEven{
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
    li{
        float: none;
        width: auto;
        & > a {
            padding: 14px 2em;
        }
    }
}
