.row-fiche-client{
    .i-checks{
        .form-check{
            padding:0
        }
    }
    .btnCollapse {
        float: right;
        text-decoration: underline;
    }
    .collapse.show ~ .btnCollapse{
        display:none
    }
    .fa-exclamation-triangle {
        color: #f9e812;
    }
}
#headerCompte{
    .subtitle{
        display: none;
    }
    .menu-elements .nav > li > a {
        font-size: 1.4em;
    }
    .affaire_detail{
        font-size: .8em;
        font-style: italic;
    }
    .fa-folder{
        font-weight: 400;
    }
    .fa{
        font-size: 1.2em;
    }
    .activeModule {
        color: #d43f3a;
    }
    a.mx-2{
        color: #66696b;
    }
}
#listMessage{
    .suivi-chat-inner{
        font-size: 1.2em;
        color: #1b1c1d;
        background: #e6ebf0;
        padding: 1em 1.5em .8em;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        .chat-author{
            display: none
        }
    }
    .text-muted{
        display: block;
        margin: 1em .5em 0;
    }
    .suivi-chat-actions{
        position: absolute;
        right: 0;
        top: 0;
        margin: .5em 0 0;
        .dropdown-menu{
            left: auto !important;
            right: 0 !important;
            a{
                color: #827e7e;
            }
        }
        .fa-edit{
            margin-right:0
        }
        .dropdown-toggle{
            color: #aaaaad
        }
    }
    .chat-element {
        &.suivi-chat-my{
            .suivi-chat-inner{
                background: #4183d7;
                color: #fff;
                margin-right: 2.5em
            }
        }
        .media-body{
            width: 90%;
            float: right;
            position: relative;
        }
    }
    .chat-element, .media-body {
        overflow: visible;
    }
}
#ficheClient{
    .nav > li > a {
        padding: 14px 1.5em;
    }
    .tab-content{
        padding: 2em 0 0;
    }
}
.listContact{
    .add_contact{
        margin-bottom: 3em;
    }
    .contact-box{
        transition: all 400ms ease-in;
        &:hover{
            box-shadow: 0 0 15px 5px rgba(25, 25, 27, 0.1);
            -webkit-box-shadow: 0 0 15px 5px rgba(25, 25, 27, 0.1);
            -moz-box-shadow: 0 0 15px 5px rgba(25, 25, 27, 0.1);
        }
        a:hover,
        a:active{
            text-decoration: none;
        }
    }
    .edit-contact{
        cursor: pointer;
    }
    .contact-box-footer{
        padding: 15px 0 0;
        .fa-trash {
            font-size: 14px;
        }
        .clearfix {
            & > * {
                float: left;
                margin-left: .5em;
            }
            & > a:first-child{
                margin-left: 0;
            }
        }
        button.btn{
            font-size: 0;
            height: 33px;
            line-height: 1.5;
            padding: .375rem .75rem;
        }
    }
}
#tab-doc{
    #DataTables_Table_0_wrapper{
        padding: 0 !important;
    }
}
#tab-temps{
    .wrap_button{
        margin: 1em 0 2em
    }
}

// modal 
#myModalContact,
#editContact{
    &.modal{
        &.inmodal {
            .modal-header {
                padding: 15px;
            }
            .modal-title{
                margin-bottom: 0 !important;
            }
        }
        .nav-tabs .nav-link{
            text-align: center;
            padding: 15px;
        }
        .wrapper-content{
            padding: 0;
        }
        textarea{
            height: 90px;
            resize: none
        }
    }
}
@media (min-width: 992px){
    #myModalContact,
    #editContact{
        &.modal{
            .col-form-label{
                -ms-flex: 0 0 16.666667%;
                flex: 0 0 16.666667%;
                max-width: 16.666667%;
            }
            .col-lg-8{
                -ms-flex: 0 0 83.333333%;
                flex: 0 0 83.333333%;
                max-width: 83.333333%;
            }
        }
    }
}