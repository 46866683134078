#myModalTache{
    .planification .chosen-choices{
        .search-choice-close{
            background: none !important;
            &::after{
                margin-top: -1px;
            }
        }
    }
    .inner-left{
        padding: 20px 25px;
        padding-right: 0;
        .modal-title{
            margin-top: 20px !important;
        }
        .unPiece:hover {
           background: none;
        }
        .titre{
            .modal-title{
                margin-top: 0 !important;
            }
        }
        .document{
            .document-content{
                padding: 14px 0 0px;
            }
        }
        .reporting-content-ajout{
            padding: 10px 0 5px;
        }
        .reporting-content{
            padding: 10px 0 0;
            td ul {
                padding: 0;
                margin: 0;
            }
            #listReporting{
                margin: 0;
            }
        }
        .commentaire-content{
            .chat-element{
                padding: 0;
                position: relative;
                overflow: visible;
                .dropdown-toggle{
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: .8rem;
                }
                .dropdown-menu{
                    left: auto !important;
                    right: 0;
                    color: #6c6c6d;
                }
                .comment-controls_edit{
                    margin-left: 0;
                }
                .comment-box-input {
                    width: 98%;
                    margin: .6rem 0 0;
                    border: 1px solid #d0d0d0 !important;
                }
            }
            .comment-centre{
                background: #f5f6f8;
                padding: .6rem 1rem;
                margin-left: 62px;
            }
        }
        .commentaire-content-ajout{
            padding: 10px 0 10px;
            .comment-box{
                padding: 8px 0;
            }
            .comment-box-input{
                margin-left: 12px;
            }
        }
    }
    .inner-right{
        background: #f3f3f3;
        padding: 20px;
        color: #2d5374;
        height: 100%;
        a{
            display: block;
            margin-bottom: .3rem;
        }
    }
    .infoTache{
        font-weight: 800;
        .btn{
            margin-left: .6rem;
        }
    }
}
#listsingTache{
    .ettiquettes{
        position: absolute;
        left: 0;
        height: 100%;
        top: 0;
        & ~ .inner-content{
            margin-left: 0;
        }
    }
    .inner-ettq{
        display: table;
        height: 100%;
    }
    .etiquette-color{
        left: 0;
        display: table-cell;
        position: relative;
    }
    .agile-list li{
        padding: 10px 2px;
    }
    .inner-content{
        margin-left: -6px;
    }
    .dropdown-menu li{
        margin-bottom: 0;
        & > a{
            margin: 0;
        }
    }
}

@media screen and (min-width: 1200px) {
    #myModalTache {
            .modal-lg{
                    max-width: 900px;
                }
        }
    }
@media screen and (min-width: 768px) {
    #myModalTache {
        .inner-left{
            .document .document-content .col-md-8{
                padding-left: 0;
            }
        }
    }
}
