.bg-blue-1 {
    background-color: #5fa4da;
    color: white;
}

.bg-blue-2 {
    background-color: #437eb0;
    color: white;
}

.bg-blue-3 {
    background-color: #4e94cf;
    color: white;
}

.bg-blue-4 {
    background-color: #2d5374;
    color: white;
}
