#dossier {
    padding-bottom: 10px;

    .dos-title {
        margin: 0 0 1.5rem;
    }
}
.dossier_fomr{
    padding-bottom: 40px;
    .wrap_field{
        margin: 0 -15px;
    }
    .dos-field {
        width: 31%;
        float: left;
        margin: 0 15px 15px;
    }
    .dos-field.field-label,
    .dos-field.field-titre{
        width: 100%;
        float: none;
        margin: 0 0 15px;
        padding: 0 15px;
        clear: both;
    }
    .dos-field.w50 {
        width: 47.7%;
    }
    .dos-field.w100 {
        width: 97%;
    }
    .field-label > *{
        margin: 0;
        background: #385c7c;
        color: #fff;
        padding: .1em 15px;
    }
    .dos-field.field-titre > * {
        text-transform: unset;
        font-weight: 700;
        font-size: 1.5em;
        margin: 0 0 1em;
    }
    .dos-field + .dos-field.field-titre{
        padding-top: 1em;
    }
}
.dos-radio,
.dos-checkbox{
    padding: 0;
    margin: 7px 0 0;
    list-style: none;
    li{
        display: block;
        float: left;
        width: 20%;
    }
    label{
        margin-bottom: 0;
        margin-left: .6rem;
        font-weight: 700;
    }
    input{
        display: inline-block;
        position: relative;
        top: .1rem;
    }
}

.dos-required{
    color: red;
}