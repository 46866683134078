#parcours, #parcours2{
    position: relative;
    padding-top: 10px;
    padding-bottom: 40px;
    &.show-interval{
        .validation-info{
            display: inline-flex;
        }
    }
    .list-parcours{
        display: inline-block;
    }
    .parcours-item{
        margin-bottom: 20px;
        .fa{
            float: right;
            margin-top: .3rem;
        }
        .info-date-conf{
            display: none;
        }
        .info-parcours{
            cursor: pointer;
        }
        &.active{
            .info-parcours{
                background: #1dc350;
                color: #fff;
                cursor: default;
            }
            .info-date-conf{
                display: inline-block;
            }
        }
        &.active:hover{
            .info-parcours{
                background: #ff0000;
                color: #fff;
                cursor: pointer;
            }
        }
    }
    .info-parcours {
        display: inline-block;
        background: #f3f3f4;
        color: #abadc5;
        font-size: 1.1rem;
        font-weight: 600;
        min-width: 350px;
        padding: 10px 15px;
        text-align: center;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -o-border-radius: 5px;
    }
    .info-date-conf{
        display: inline-block;
        vertical-align: middle;
        margin-left: 1rem;
        font-size: 1.2em;
        font-weight: 600;
        input{
            display: inline-block;
            margin-left: .5rem;
        }
    }
    .validation-info{
        display: none;
        vertical-align: top;
        position: relative;
        top: 20px;
        height: 134px;
        border-left: 4px solid #2dc65a;
        justify-content: center;
        align-items: center;
        font-size: 2.5em;
        font-weight: 700;
        color: #2dc65a;
        padding-left: .7em;
        margin-left: .4em;
    }
    .list-etapes{
        position: relative;
        top: 0px;
        display: inline-block;
        vertical-align: top;
        .etape-item{
            text-align: right;
            border-right: 2px solid #7e7f86;
            line-height: 46px;
            padding-right: .6rem;
            color: #7e7f86;
            font-weight: 600;
            margin-top: 23px;
            margin-right: 1em;
        }
    }
}
