/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.9.2
 *
*/

// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

//bootstrap 
@import "~bootstrap/scss/bootstrap";

// Variables, Mixins
@import "base/variables";
@import "base/mixins";

// INSPINIA Theme Elements
@import "base/typography";
@import "base/glyphicons";
@import "base/navigation";
@import "base/top_navigation";
@import "base/buttons";
@import "base/badges_labels";
@import "base/elements";
@import "base/sidebar";
@import "base/base";
@import "base/pages";
@import "base/chat";
@import "base/metismenu";
@import "base/spinners";

// Landing page
@import "base/landing";

// RTL Support
@import "base/rtl";

// For demo only - config box style
@import "base/theme-config";

// INSPINIA Skins
@import "base/skins";
@import "base/md-skin";

// Media query style
@import "base/media";

// Custom style
// Your custom style to override base style
@import "base/custom";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}


@import "variables";
@import "main";
@import "page/account";
@import "page/affaire";
@import "page/fiche_client";
@import "page/environnement";
@import "page/taches";
@import "page/agenda";
@import "page/financiere_parcours";
@import "page/financiere_questionnaire";
@import "page/financiere_dossier";
@import "page/compte_lycee_rabelais";