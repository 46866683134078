.questionnaire{
    padding-bottom: 40px;
    .nav{
        justify-content: center;
        margin-bottom: 30px;
        a{
            border-bottom: 2px solid #fff;
            &.active{
                border-color: #385c7c;
                color: #385c7c;
            }
        }
    }
    /*.left-content,
    .right-content{
        float: left;
        width: 50%;
    }*/
    .left-content{
        float: left;
        width: 70%;
    }
    .right-content{
        float: left;
        width: 30%;
    }
    .quest-title{
        margin: 0 0 1.5rem;
    }
    .quest-number{
        display: inline-block;
        vertical-align: middle;
        line-height: 25px;
        background: #ed5565;
        color: #fff;
        padding: 8px 15px;
        font-size: 1.2em;
        font-weight: 600;
        margin-right: 1rem;
        width: 47px;
        text-align: center;
    }
    .lists-quets{
        margin-top: 2rem;
        .quest-item:nth-child(even){
            background: #ecebeb;
        }
    }
    .gfield_radio {
        padding: 0;
        margin: 7px 0 0;
        list-style: none;
        li{
            display: block;
            float: left;
            width: 20%;
            padding-top: .3rem;
        }
        label{
            margin-bottom: 0;
            margin-left: .6rem;
            font-weight: 700;
            vertical-align: middle;
        }
        input{
            display: inline-block;
            position: relative;
            top: .1rem;
            width: 1.5em;
            height: 1.5em;
            vertical-align: middle;
        }
    }
    .wrap-graph {
        svg ~ div{
            left: 0 !important;
        }
    }
    .ct-perfect-fourth{
        height: 430px;
    }
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start{
    font-size: 1.2em;
}

#ct-chart4 {
    margin-bottom: 3em;
    .ct-label{
        span{
            display: inline-block;
            margin-left: .8em;
        }
    }
}
#ct-chart5 {
    margin-bottom: 3em;
    .ct-label{
        span{
            display: inline-block;
            margin-left: .8em;
        }
    }
}