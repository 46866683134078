.caTable {
    font-size: 20px;
}

.account-progress {
    h6 {
        font-weight: 400;
        font-size: 14px;
    }
}

#financiereModule {
    .icon-wrapper {
        .fas, .fa {
            color: #8d9192;
        }
    }
}
